import Lottie from "lottie-react";
import React, { useState } from "react";
import carrerAnim from '../assets/animations/career.json'
const Careers = () => {
    const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

    return (
        <div className="container m-auto min-h-screen bg-gradient-to-r flex flex-col items-center justify-center">
            <div className="max-w-280 m-auto text-center my-5">
                <h1 className="text-4xl font-bold m-2">Career Section</h1>
                <p className="mt-2 italic">
                    We're excited to meet passionate and talented people who want to make a real impact. Whether you're looking to jumpstart your career with an internship
                    or you're ready for a full-time role to elevate your professional journey, we offer a vibrant and supportive environment where you can truly shine.
                    Join us and be part of a team that values your growth and creativity.
                </p>
            </div>
            <div className=" my-4 px-4 rounded-lg shadow-lg flex w-full max-w-4xl">
                <div className=" hidden w-1/2 p-8 bg-blue-900 text-white md:flex flex-col justify-center items-center rounded-l-md">
                    <h2 className="text-2xl font-bold mb-4">Jumpstart Your Career</h2>
                    <p className="mb-4">with Our Internship Program & Job Programs</p>
                    <p className="text-3xl">Apply Now</p>
                    <div>
                        <Lottie animationData={carrerAnim} className=""></Lottie>
                    </div>
                </div>
                <div className="md:w-1/2 p-8  bg-indigo-600 sm:bg-white rounded-r-md">
                    <h2 className="text-2xl font-bold mb-4">Registration Form</h2>
                    <form className="space-y-4">
                        <div>
                            <label className="block text-gray-700">Full Name:<span className="text-red-700">*</span> </label>
                            <input type="text" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600" placeholder="ex: Johny Beck" required />
                        </div>
                        <div>
                            <label className="block text-gray-700">Your Email: <span className="text-red-700">*</span> </label>
                            <input type="email" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600" placeholder="ex:youraccount@email.com" required />
                        </div>
                        <div>
                            <label className="block text-gray-700">Phone Number:<span className="text-red-700">*</span> </label>
                            <input type="tel" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600" placeholder="+91xxxxxxxxxx" required />
                        </div>
                        <div>
                            <label className="block text-gray-700">Current Location:<span className="text-red-700">*</span> </label>
                            <input type="text" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600" placeholder="H.No/Appartment No. Area " required />
                        </div>
                        <div>
                            <label className="block text-gray-700">Uplaod Resume: <span className="text-red-700">*</span> </label>
                            <input type="file" accept=".doc,.docx,.pdf" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2" required />
                        </div>
                        <div>
                            <label className="block text-gray-700">LinkedIn Profile <span className="text-red-700">*</span> </label>
                            <input type="url" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2" required placeholder="https://www.linkedin.com/in/your-account/" />
                        </div>
                        <div>
                            <label className="block text-gray-700">GitHub Profile</label>
                            <input type="url" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2" placeholder="https://github.com/your-account" />
                        </div>
                        <div>
                            <label className="block text-gray-700">Cover Letter</label>
                            <textarea name="message" className=" resize-none w-full px-4 py-2 border-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600">
                            </textarea>
                            {/* <input type="" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600" placeholder="H.No/Appartment No. Area " /> */}
                        </div>
                        <div className="flex items-center">
                            <input type="checkbox" className="mr-2" id="terms" required onChange={handleCheckboxChange} checked={isChecked}/>
                            <label className="text-gray-700" htmlFor="terms">By signing up, you agree to the <a href="#" className="text-indigo-600">Term of Service </a><span className="text-red-700">*</span></label>
                        </div>
                        <button type="submit" className={`w-full bg-indigo-600 text-white py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 hover:bg-indigo-700 ${!isChecked ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={!isChecked}>Register</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Careers;